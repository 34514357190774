import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  productTitle: "letterSpacing: 0.1rem",
  productPrice: "fontSize: 2.1rem; fontWeight: 500",
}));

const ProductInfo = ({ displayTitle, selectedProduct, descriptionHtml }) => {
  const classes = useStyles();
  const { price, availableForSale: isProductAvailable } = selectedProduct;
  return (
    <>
      <div className="mt-4">
        <h3 className={`text-primary ${classes.productTitle}`}>
          {displayTitle}
        </h3>
      </div>

      <section aria-labelledby="information-heading" className="mt-4">
        <h2 id="information-heading" className="sr-only">
          Product information
        </h2>

        <div className="flex items-center">
          <p className={classes.productPrice}>$ {price}</p>
        </div>

        <div className="mt-4 space-y-6">
          <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
        </div>

        <div className="mt-6 flex items-center">
          <CheckIcon
            className={`flex-shrink-0 w-12 h-12 ${
              isProductAvailable ? "text-green-500" : "text-red-500"
            }`}
            aria-hidden="true"
          />
          <p
            className={`ml-2 mt-0 ${
              isProductAvailable ? "text-gray-900" : "text-red-500"
            }`}
          >
            {isProductAvailable ? "In stock" : "Out of stock"}
          </p>
        </div>
      </section>
    </>
  );
};

export default ProductInfo;
