import React from "react";
import { Link } from "gatsby";

// Create a dedicated Breadcrumbs component
const ProductBreadcrumbs = ({ dislayTitle }) => {
  return (
    <nav aria-label="Breadcrumb">
      <div className="flex items-center">
        <Link to="/shop" className="font-medium">
          Shop
        </Link>
        <svg
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          aria-hidden="true"
          className="ml-2 flex-shrink-0 h-5 w-5"
        >
          <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
        </svg>
        <Link to="/shop" className="font-medium">
          {dislayTitle}
        </Link>
      </div>
    </nav>
  );
};

export default ProductBreadcrumbs;
