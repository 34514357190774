import React from "react";
import { Link } from "gatsby";

const AddToCart = ({ isProductAvailable, handleAddToCart }) => {
  return (
    <div className="mt-12">
      {isProductAvailable ? (
        <button
          type="submit"
          className="w-full bg-primary border border-transparent rounded-md py-6 flex items-center justify-center  text-white hover:bg-buttonHover focus:outline-none  focus:bg-buttonHover text-base"
          onClick={e => handleAddToCart(e)}
        >
          Add to Cart
        </button>
      ) : (
        <Link
          to="/shop"
          className="w-full bg-primary border border-transparent rounded-md py-6 flex items-center justify-center  text-white hover:bg-buttonHover focus:outline-none  focus:bg-buttonHover text-base"
        >
          Return to Shop
        </Link>
      )}
    </div>
  );
};

export default AddToCart;
