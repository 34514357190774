import React from "react";
import { RadioGroup } from "@headlessui/react";
import { makeStyles } from "@material-ui/core";
import { Link } from "gatsby";
import AddToCart from "./addToCart";
const useStyles = makeStyles(() => ({
  productOption: {
    maxWidth: "41rem !important",
  },
}));

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductOptions = ({
  allVariants,
  selectedProduct,
  setSelectedProduct,
  isProductAvailable,
  handleAddToCart,
}) => {
  const classes = useStyles();

  return (
    <form className={classes.productOption}>
      {allVariants && allVariants.length > 1 && (
        <div className="sm:flex sm:justify-between">
          <RadioGroup value={selectedProduct} onChange={setSelectedProduct}>
            <RadioGroup.Label className="block font-medium mt-12 mb-7">
              Type
            </RadioGroup.Label>
            <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
              {allVariants.map((item, i) => {
                const { displayName: titleProduct } = item;
                return (
                  <RadioGroup.Option
                    as="div"
                    key={i}
                    value={item}
                    className={({ active }) =>
                      classNames(
                        active ? "ring-2 text-primary" : "",
                        "relative block border border-gray-300 rounded-lg"
                      )
                    }
                  >
                    {({ active, checked }) => {
                      return (
                        <>
                          <RadioGroup.Label
                            as="p"
                            className="mt-0 text-primary py-7 px-11 cursor-pointer focus:outline-none "
                          >
                            {titleProduct}
                          </RadioGroup.Label>
                          <div
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-indigo-500"
                                : "border-transparent",
                              "absolute -inset-px rounded-lg pointer-events-none"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      );
                    }}
                  </RadioGroup.Option>
                );
              })}
            </div>
          </RadioGroup>
        </div>
      )}
      <div className="mt-12">
        <AddToCart
          isProductAvailable={isProductAvailable}
          handleAddToCart={handleAddToCart}
        />
      </div>
      <div className="mt-10 text-center w-full">
        <Link
          className=" w-full
        relative block border border-gray-300 rounded-lg
          text-base py-6  hover:text-buttonHover hover:border-buttonHover"
          to="/cart"
        >
          View your cart
        </Link>
      </div>
    </form>
  );
};

export default ProductOptions;
