import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import Layout from "../components/theme/layout";
import Layout from "../theme/layout";
import ShoppingLayout from "../shopping/shoppingLayout";

import { useShopifyFunctions } from "../../context/StoreContext";

import ProductBreadcrumbs from "./productBreadcrumbs";

import ProductImage from "./productImage";

import ProductOptions from "./productOptions";

const useStyles = makeStyles(() => ({
  productOption: "maxWidth: 41rem !important",
  productTitle: "letterSpacing: 0.1rem",
  productPrice: "fontSize: 2.1rem; fontWeight: 500",
  productImgDisplay: "maxWidth: 400px",
}));

import ProductInfo from "./productInfo";

function getShopName(collection) {
  const collectionMapping = {
    chemicals: "Shop Filters",
    accessories: "Shop Accessories",
    filters: "Shop Filters",
  };
  return collectionMapping[collection] || "Shop Product.";
}

function ProductDetails({ data }) {
  const classes = useStyles();
  const { allStrapiCoversAccessories, shopifyProduct } = data;

  const collectionName = shopifyProduct.collections[0]?.handle || "chemicals";
  const layoutData = [...allStrapiCoversAccessories.edges];
  const { addItem } = useShopifyFunctions();

  const {
    title,
    descriptionHtml,
    images: allImages = [],
    variants: allVariants = [],
  } = shopifyProduct;

  // const defaultProduct =
  //   allVariants.length > 0 ? allVariants[0] : shopifyProduct;
  const defaultProduct =
    allVariants.find(variant => variant.availableForSale) || shopifyProduct;
  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);

  const _handleAddToCart = async e => {
    e.preventDefault();
    await addItem({
      variantId: selectedProduct.shopifyId,
      quantity: 1,
    });
  };

  // const displayImg = selectedProduct.image
  //   ? selectedProduct.image.originalSrc
  //   : allImages[0]?.originalSrc || "";
  const displayImg = selectedProduct.image
    ? selectedProduct.image.originalSrc
    : allImages[0]?.originalSrc || "";
  const displayTitle = selectedProduct.title || title;
  const isProductAvailable = selectedProduct.availableForSale === true;

  return (
    <Layout>
      {/* <Seo title={title ? title : "Product Item"} /> */}
      <ShoppingLayout data={layoutData} shopName={getShopName(collectionName)}>
        <div className="bg-white py-24">
          <Container>
            <div className="max-w-full w-max mx-auto px-4 sm:px-6  lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
              <div className="max-w-full lg:self-end">
                <ProductBreadcrumbs dislayTitle={displayTitle} />
                <ProductInfo
                  displayTitle={displayTitle}
                  selectedProduct={selectedProduct}
                  descriptionHtml={descriptionHtml}
                />
              </div>

              <div className="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 h-full lg:self-center">
                <ProductImage
                  displayImg={displayImg}
                  title={title}
                  classes={classes}
                />
              </div>

              <div className=" max-w-full lg:col-start-1 lg:row-start-2 lg:self-start">
                <section aria-labelledby="options-heading">
                  <h2 id="options-heading" className="sr-only">
                    Product options
                  </h2>

                  <ProductOptions
                    allVariants={allVariants}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    isProductAvailable={isProductAvailable}
                    handleAddToCart={_handleAddToCart}
                  />
                </section>
              </div>
            </div>
          </Container>
        </div>
      </ShoppingLayout>
    </Layout>
  );
}

export default ProductDetails;
