import React from "react";
import { graphql } from "gatsby";
import ProductDetails from "../components/productDetails";

function ProductTemplate({ data }) {
  return <ProductDetails data={data} />;
}

export default ProductTemplate;

export const postQuery = graphql`
  query ShopifyProductById($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      id
      totalInventory
      shopifyId
      handle
      title
      descriptionHtml
      productType
      storefrontId
      images {
        originalSrc
      }
      collections {
        handle
        title
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      variants {
        availableForSale
        displayName
        image {
          originalSrc
        }
        weight
        id
        price
        shopifyId
      }
    }
    allStrapiCoversAccessories {
      edges {
        node {
          id # id of the node
          ca_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          access_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
